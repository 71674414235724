import './App.css';
import Navbar from "./components/Navbar";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Premium from './pages/premium';
import Home from './pages/home';
import Workers from './pages/workerList';
import Callback from './pages/callback';
import GuildsSelection from './pages/guilds';
import Guild from './pages/guild';
import PrivacyPolicy from './pages/GoldWolf/privacy-policy';
import TermsOfService from './pages/GoldWolf/terms-of-service';
import Dashboard from './pages/dashboard';

function App() {
  return (
    <Router>
      <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/goldwolf/privacy-policy" element={<PrivacyPolicy/>} />
          <Route exact path="/goldwolf/terms-of-service" element={<TermsOfService/>} />
          <Route exact path='/dashboard/:id' element={<Dashboard />} />
          <Route exact path="/premium" element={<Premium />} />
          <Route exact path="/workers/:id" element={<Workers />} />
          <Route exact path='/guilds' element={<GuildsSelection />} />
          //<Route exact path='/guild/:id' element={<Guild />} />
          <Route path='/callback' element={<Callback />} />
      </Routes>
    </Router>
  );
}

export default App;
