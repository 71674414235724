import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MuiAvatar from '@mui/material/Avatar';
import MuiListItemAvatar from '@mui/material/ListItemAvatar';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Select, { selectClasses } from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import { CircularProgress } from '@mui/material';

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: 28,
  height: 28,
  backgroundColor: (theme.vars || theme).palette.background.paper,
  color: (theme.vars || theme).palette.text.secondary,
  border: `1px solid ${(theme.vars || theme).palette.divider}`,
}));

const ListItemAvatar = styled(MuiListItemAvatar)({
  minWidth: 0,
  marginRight: 12,
});

export default function SelectContent() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [company, setCompany] = React.useState('');

  const handleChange = (event) => {
    setCompany(event.target.value);
    navigate(`/dashboard/${event.target.value}`)
  };

  const [guilds, setGuilds] = React.useState(null);

  React.useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/guilds`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
          credentials: 'include', 
      })            
      .then(response => response.json())
      .then(data => {
          // Filter the guilds where the user has 'MANAGE_GUILD' permission (0x20)
          const manageableGuilds = data.filter(guild => {
              const permissions = BigInt(guild.permissions_new || guild.permissions);
              return (permissions & BigInt(0x20)) === BigInt(0x20);
          });

          console.log(manageableGuilds)
          setGuilds(manageableGuilds);
          setCompany(id)
      })
      .catch(error => {
          console.error('Error:', error);
      });
  }, []);

  return (
    <Select
      labelId="company-select"
      id="company-simple-select"
      value={company}
      onChange={handleChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Select company' }}
      fullWidth
      sx={{
        maxHeight: 56,
        width: 215,
        '&.MuiList-root': {
          p: '8px',
        },
        [`& .${selectClasses.select}`]: {
          display: 'flex',
          alignItems: 'center',
          gap: '2px',
          pl: 1,
        },
      }}
    >
      <ListSubheader sx={{ pt: 0 }}>Owner</ListSubheader>
      {guilds ? (
        guilds.map((guild) => (
        <MenuItem value={guild.id} key={guild.id}>
          <ListItemAvatar>
            <Avatar src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.webp`} alt={guild.name} />
          </ListItemAvatar>
          <ListItemText primary={guild.name} />
        </MenuItem>
      ))) : (
        <CircularProgress />
      )}
      <ListSubheader>Manager</ListSubheader>
      <MenuItem value={30}>
        <ListItemAvatar>
          <Avatar alt="Sitemark Store">
            <ConstructionRoundedIcon sx={{ fontSize: '1rem' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Sitemark-Admin" secondary="Web app" />
      </MenuItem>
      <Divider sx={{ mx: -1 }} />
      <MenuItem value={40}>
        <ListItemIcon>
          <AddRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Invite to server" secondary="Add server" />
      </MenuItem>
    </Select>
  );
}